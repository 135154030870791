import '@babel/polyfill';

import styles from '../css/app.css';
import lazySizes from 'lazysizes';

import( /* webpackChunkName: "lazyszies-bgset" */ 'lazysizes/plugins/bgset/ls.bgset.min' );
import( /* webpackChunkName: "lazyszies-bgset" */ 'lazysizes/plugins/bgset/ls.bgset.min' );
import( /* webpackChunkName: "lazyszies-parentfit" */ 'lazysizes/plugins/parent-fit/ls.parent-fit.min' );

if ( !window.HTMLPictureElement || document.msElementsFromPoint ) {
  import( /* webpackChunkName: "lazyszies-polyfillie" */ 'lazysizes/plugins/respimg/ls.respimg.min' );
}

const site = () => import(/* webpackChunkName: "site" */ './site.js');

// App main
site().then( ( { default: siteApp } ) => {
  window.siteApp = siteApp;
  window.siteApp.init();
} );
